// src/components/Card.tsx

import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";

interface BlogPostNode {
  id: string;
  excerpt: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    thumbnail: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    category: string;
  };
}

interface CardProps {
  post: BlogPostNode;
}

const Card: React.FC<CardProps> = ({ post }) => {
  // Extract gatsbyImageData and handle potential undefined values
  const imageData = post.frontmatter.thumbnail?.childImageSharp?.gatsbyImageData;

  return (
    <div className="p-4 w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
      <div className="h-full border border-1 border-white/20 border-opacity-60 rounded-lg overflow-hidden">
        {imageData && (
          <Link to={post.fields.slug}>
            <GatsbyImage image={imageData} alt="blog" className="w-full object-cover object-center" />
          </Link>
        )}
        <div className="p-6">
          <h2 className="text-xs title-font font-medium text-gray-700 mb-2">
            {post.frontmatter.category}
          </h2>
          <h1>
            <Link
              to={post.fields.slug}
              className="text-xl md:text-2xl lg:text-xl font-medium text-gray-100 mb-3"
            >
              {post.frontmatter.title}
            </Link>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Card;
